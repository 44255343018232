import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import TermsAndConditions from './pages/TermsAndConditions';
import CancellationAndRefund from './pages/CancellationAndRefund';
import Corporate from './pages/Corporate';
import AnnualHealthCheckup from './pages/AnnualHealthCheckup';
import PreEmploymentHealthCheck from './pages/PreEmploymentHealthCheck';
import OccupationalHealthCentres from './pages/OccupationalHealthCentres';

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <Header />
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/annual-health-checkup" element={<AnnualHealthCheckup />} />
            <Route path="/pre-employment-health-check" element={<PreEmploymentHealthCheck />} />
            <Route path="/occupational-health-centres" element={<OccupationalHealthCentres />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
          </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;