import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import corporate from "../assets/images/corporate.png";
import annualCheck from "../assets/images/annualCheck.png";
import preEmployment from "../assets/images/preEmployment.png";
import occupationalHealth from "../assets/images/occupationalHealth.png";

const Corporate = () => {
    const sections = [
        {
          title: "Annual Health Check Up",
          description:
            "An Annual Health Check detects health issues early and promotes well-being. Regular check-ups prevent severe conditions and support a healthier workplace.",
          link: "/annual-health-checkup",
          image: annualCheck
        },
        {
          title: "Pre-Employment Health Check Up",
          description:
            "A Pre-Employment Health Check ensures candidates are fit for the role, assessing physical and mental health. It helps minimize health risks, ensures compliance with safety regulations, and supports an inclusive work culture.",
          link: "/pre-employment-health-check",
          image: preEmployment
        },
        {
          title: "Occupational Health Centres",
          description:
            "Occupational Health Centers focus on preventing, diagnosing, and managing work-related illnesses and injuries. With a team of specialists, they provide tailored services to ensure employee well-being and compliance with health and safety standards.",
          link: "/occupational-health-centres",
          image: occupationalHealth
        },
    ];

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [showPopup, setShowPopup] = useState(false);
    const [sending, setSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const onSubmit = async (data) => {
        setSending(true);
        setErrorMessage('');
        
        try {
            const response = await fetch("https://send-email.admin-0c3.workers.dev/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            });
        
            const result = await response.json();
        
            if (response.ok) {
            setShowPopup(true);
            reset();
            } else {
            setErrorMessage(result.error || 'Failed to send message. Please try again later.');
            }
        } catch (error) {
            setErrorMessage('Failed to send message. Please try again later.');
        }
        setSending(false);
    };      

    return (
    <div>
        <section className="relative text-white py-24">
            <img src={corporate} alt="corporate" className="absolute inset-0 w-full h-full object-cover" />
            <div className="absolute inset-0 bg-indigo-900 bg-opacity-50"></div>
            <div className="relative container mx-auto px-8 text-center z-10">
            <h1 className="text-5xl font-extrabold mb-6">Corporate Services</h1>
            <p className="text-lg text-gray-200 max-w-2xl mx-auto">
                CallMediLife provides comprehensive Corporate Health Check-Up services to ensure a healthier, more productive workforce.
            </p>
            </div>
        </section>

        <div className="text-center mt-16 my-5"> 
            <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 mt-20 lg:mt-0 rounded-full mx-auto lg:mx-0">
            Services
            </button>
            <h2 className="text-3xl font-bold mt-4 text-gray-800">
            Corporate Services
            </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl mx-auto p-6">
            {sections.map((section, index) => (
            <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg p-6 border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl text-center flex flex-col justify-between h-full"
            >
                <div className="text-center">
                <img src={section.image} alt={section.title} className="w-64 h-64 mb-4 mx-auto" />
                <h2 className="text-lg font-bold text-indigo-800">{section.title}</h2>
                <p className="text-gray-700 text-sm leading-relaxed mt-4 mb-9">{section.description}</p>
                </div>
                <div className="mt-auto flex justify-center">
                <Link 
                    to={section.link} 
                    className="bg-indigo-900 text-white font-semibold px-4 py-2 rounded-full hover:bg-indigo-700 transition"
                >
                    Read More
                </Link>
                </div>
            </div>
            ))}
        </div>
        <div className="text-center mt-16"> 
                <button className="text-indigo-950 font-semibold bg-indigo-100 px-4 py-1 mt-20 lg:mt-0 rounded-full mx-auto lg:mx-0">
                    Contact Us
                </button>
            </div>
            <div className="max-w-4xl mx-auto p-6 mb-10">
                <h2 className="text-3xl font-bold text-gray-800 text-center">
                    Personalized solutions for better teams
                </h2>
                <p className="text-gray-600 text-center mt-2">
                    Enhance employee well-being with customized programs designed to meet the unique needs of your organization.
                </p>
        <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                    <label className="block text-gray-700 font-semibold">Full Name</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        {...register('name', { required: 'Name is required' })}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        placeholder="Full Name"
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold">Email</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: 'Invalid email address'
                            }
                        })}
                        placeholder="Enter Email Address"
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold">Phone Number</label>
                    <input
                        id="phone"
                        type="number"
                        name="phone"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        {...register('phone', { required: 'Phone is required' })}
                        placeholder="Enter Phone Number"
                    />
                    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
                <div>
                    <label className="block text-gray-700 font-semibold">Company Name</label>
                    <input
                        id="company"
                        type="text"
                        name="company"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        {...register('company', { required: 'Company is required' })}
                        placeholder="Enter Company Name"
                    />
                    {errors.company && <p className="text-red-500 text-sm mt-1">{errors.company.message}</p>}
                </div>
                <div>
                    <label htmlFor="subject" className="block text-gray-700 font-semibold">Subject</label>
                    <input
                        id="subject"
                        type="text"
                        {...register('subject', { required: 'Subject is required' })}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        placeholder="Subject"
                    />
                    {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject.message}</p>}
                </div>
                <div className="md:col-span-2">
                    <label className="block text-gray-700 font-semibold">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                        rows="4"
                        {...register('message', { required: 'Message is required' })}
                        placeholder="Enter Message"
                    />
                    {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message.message}</p>}
                </div>
            </div>

            {/* Error message */}
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            {/* Send button */}
            <button
                type="submit"
                className="my-5 w-full py-3 px-6 bg-indigo-950 hover:bg-indigo-800 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-950 transition-all duration-300"
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Message'}
            </button>
        </form>
        
        {/* Popup */}
        {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Thank You!</h2>
                <p className="text-gray-600">Your message has been sent successfully. We'll get back to you soon.</p>
                <button
                onClick={() => setShowPopup(false)}
                className="mt-4 px-6 py-2 bg-indigo-950 hover:bg-indigo-800 text-white font-semibold rounded-lg"
                >
                Close
                </button>
            </div>
            </div>
        )}
        </div>
    </div>
    );
};

export default Corporate;