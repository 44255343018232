import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import logo from '../assets/images/cml_logo.png';

const Footer = () => {
  return (
    <footer className="bg-gray-200 pt-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-3">
        {/* Logo and description */}
        <div className="flex-1">
          <img src={logo} alt="CallMediLife Logo" className="h-16 inline-block mx-3" />
          <p className="text-gray-900 mt-4">
            CallMediLife Healthcare Services Pvt. Ltd is a revolutionary healthcare service provider that brings together a wide range of resources and professionals under one roof.
          </p>
        </div>

        {/* Links */}
        <div className="flex-1">
          <h3 className="text-xl font-semibold mb-4">Links</h3>
          <ul className="space-y-2">
            <li><a href="/" className="text-gray-900 hover:text-red-400">Home</a></li>
            <li><a href="/about" className="text-gray-900 hover:text-red-400">About</a></li>
            <li><a href="/contact" className="text-gray-900 hover:text-red-400">Contact</a></li>
            <li><a href="/privacy" className="text-gray-900 hover:text-red-400">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions" className="text-gray-900 hover:text-red-400">Terms And Conditions</a></li>
            <li><a href="/cancellation-and-refund" className="text-gray-900 hover:text-red-400">Cancellation And Refund</a></li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="flex-1">
          <h3 className="text-xl font-semibold mb-4">Have a Question?</h3>
          <div className="space-y-4 text-gray-900">
            <p className="flex">
              <span><FaMapMarkerAlt className="inline mr-2" /></span>
              Unit No. 303 - 304, 3rd floor, Jay Antariksh, Thakur House, Makwana Road, Marol Andheri East, Mumbai, Maharashtra 400059
            </p>
            <p><FaPhoneAlt className="inline mr-2" />+91 1800 266 3227</p>
            <p><FaEnvelope className="inline mr-2" />customerservice@callmedilife.com</p>
          </div>
        </div>

        {/* Opening Hours */}
        <div className="flex-1">
          <h3 className="text-xl font-semibold mb-4">Opening Hours</h3>
          <ul className="space-y-2 text-gray-900">
            <li><FaClock className="inline mr-2" />7.30AM to 8.30PM (All Days)</li>
          </ul>
          <h3 className="text-xl font-semibold mt-7">Follow Us</h3>
          <div className="flex space-x-4 mt-5">
            <a href="https://www.facebook.com/CallMediLife" target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:text-red-400"><FaFacebookF /></a>
            <a href="https://x.com/callmedilife" target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:text-red-400"><FaTwitter /></a>
            <a href="https://www.linkedin.com/company/callmedilife" target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:text-red-400"><FaLinkedinIn /></a>
            <a href="https://www.instagram.com/callmedilife" target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:text-red-400"><FaInstagram /></a>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="bg-gray-400 py-4 text-center mt-10">
        <p className="text-gray-700">
          &copy; {new Date().getFullYear()} <b>CallMediLife Healthcare Services Pvt. Ltd.</b> All rights reserved.
        </p>
        <p className="text-gray-600 text-sm">
        </p>
      </div>
    </footer>
  );
};

export default Footer;