import React from 'react';
import { FaEye, FaDeaf, FaStethoscope, FaShieldAlt, FaClipboardCheck, FaBriefcaseMedical, FaHandsHelping, FaClipboardList } from "react-icons/fa";

function PreEmploymentHealthCheckPage() {
  return (
    <div className="max-w-6xl mx-auto py-16 px-8 space-y-12">
      {/* Header Section */}
      <div className="text-center space-y-4">
        <h1 className="text-3xl font-extrabold text-indigo-900 flex justify-center items-center gap-4">
          Pre-Employment Health Check Up
        </h1>
        <p className="text-gray-700 font-sm max-w-2xl mx-auto">
            Ensure Your Workforce is Ready to Excel!
        </p>
      </div>

      {/* Description Section */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-indigo-900 mb-4">Why is this important?</h2>
        <p className="text-gray-700 leading-relaxed text-md">
            A Pre-Employment Health Check ensures that prospective employees are fit for the role they are being hired for. It evaluates physical and mental fitness, minimizing workplace health risks and promoting a safe working environment. By identifying potential health concerns before employment, this process helps employers comply with workplace health and safety regulations and fosters an inclusive work culture.
        </p>
      </div>

      {/* Tests Included Section */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-indigo-900 mb-4">Pre-Employment Health Check include:</h2>
        <ul className="space-y-4">
          {[ 
            { icon: <FaStethoscope />, text: "General physical examination." },
            { icon: <FaEye />, text: "Vision and hearing tests." },
            { icon: <FaDeaf />, text: "Screening for infectious diseases." },
            { icon: <FaClipboardCheck />, text: "Tests specific to the job's requirements, such as lung function or musculoskeletal assessments." }
          ].map((item, index) => (
            <li key={index} className="flex items-center gap-3 text-md text-gray-800">
              <span className="text-indigo-700 text-2xl">{item.icon}</span>
              {item.text}
            </li>
          ))}
        </ul>
      </div>

      {/* Benefits Section */}
      <div className="bg-white p-10 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-indigo-900 mb-6">Benefits for Employers:</h2>
        <p className="text-sm font-semibold text-gray-500 mb-6">If there were no previous screening tests done in the past, you could opt for them now</p>
        <ul className="space-y-6">
          {[ 
            { icon: <FaShieldAlt />, text: "Ensures the health and safety of the workforce." },
            { icon: <FaBriefcaseMedical />, text: "Reduces absenteeism and increases productivity." },
            { icon: <FaClipboardCheck />, text: "Reduced Medical Reimbursement Costs to the Employer Over Time." },
            { icon: <FaHandsHelping />, text: "Promotes a culture of health and wellness at the workplace." },
            { icon: <FaClipboardList />, text: "Managed Health Records." }
          ].map((item, index) => (
            <li key={index} className="flex items-center gap-3 text-md text-gray-800">
              <span className="text-indigo-900 text-2xl">{item.icon}</span>
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PreEmploymentHealthCheckPage;