import { FaUserMd, FaHeartbeat, FaBriefcaseMedical, FaUserCheck, FaCheckCircle } from "react-icons/fa";

function OccupationalHealthCentersPage() {
  return (
    <div className="max-w-6xl mx-auto py-16 px-8">
      {/* Header Section */}
      <div className="text-center space-y-4 mb-12">
        <h1 className="text-2xl font-extrabold text-blue-900 flex justify-center items-center gap-4">
          Occupational Health Centres & Medical Room Management
        </h1>
        <p className="text-gray-700 text-lg font-medium max-w-2xl mx-auto">
          Ensuring workplace health and safety through specialized medical facilities.
        </p>
      </div>

      {/* Description Section */}
      <p className="text-gray-700 leading-relaxed text-lg mb-12 text-justify">
        Occupational Health Centers are specialized facilities dedicated to the health and well-being of employees in the workplace.
        These centres focus on the prevention, diagnosis, and management of work-related illnesses and injuries. With a multidisciplinary
        team of occupational health specialists (Doctor & Nurse), these centres provide services tailored to the unique needs of the
        workforce, ensuring compliance with health and safety standards.
      </p>

      {/* Services Section */}
      <h2 className="text-xl font-semibold text-blue-800 mb-6">Occupational Health Centers Services Include:</h2>
      <ul className="space-y-4 mb-12">
        {[ 
          { icon: <FaHeartbeat />, text: "Health risk assessments and workplace hazard evaluations." },
          { icon: <FaBriefcaseMedical />, text: "Medical surveillance programs and fitness-for-duty assessments." },
          { icon: <FaUserMd />, text: "Ergonomic evaluations and injury prevention strategies." },
          { icon: <FaCheckCircle />, text: "Emergency care and first aid services." }
        ].map((item, index) => (
          <li key={index} className="flex items-center gap-3 text-lg text-gray-800">
            <span className="text-blue-700 text-3xl">{item.icon}</span>
            {item.text}
          </li>
        ))}
      </ul>

      {/* Benefits Section */}
      <h2 className="text-xl font-semibold text-blue-800 mb-6">Benefits of OHCs:</h2>
      <ul className="space-y-6 mb-12">
        {[ 
          { icon: <FaUserCheck />, text: "Promotes a healthier and safer work environment." },
          { icon: <FaHeartbeat />, text: "Reduces workplace incidents and associated costs." },
          { icon: <FaCheckCircle />, text: "Enhances employee morale and job satisfaction." }
        ].map((item, index) => (
          <li key={index} className="flex items-center gap-3 text-lg text-gray-800">
            <span className="text-blue-700 text-3xl">{item.icon}</span>
            {item.text}
          </li>
        ))}
      </ul>

      {/* Contact Section */}
      <div className="py-8">
        <p className="text-xl text-gray-800 font-semibold">Let us help your organization thrive with healthier, happier employees.</p>
        <p className="text-xl text-blue-700 font-semibold mt-2">Contact us today!</p>
      </div>
    </div>
  );
}

export default OccupationalHealthCentersPage;