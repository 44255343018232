import { FaHeartbeat, FaClipboardList, FaUserNurse, FaMedkit, FaHandsHelping, FaAward } from "react-icons/fa";

function AnnualHealthCheckPage() {
  return (
    <div className="max-w-6xl mx-auto m-16 flex flex-col gap-8">
      {/* Header Section */}
      <div className="mb-3">
        <h1 className="text-4xl font-bold text-indigo-900 flex items-center gap-3">
          <FaHeartbeat className="text-red-500" /> Annual Health Check Up
        </h1>
      </div>
      
      {/* Description Section */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <p className="text-lg font-bold my-2">Your Health, Our Priority - Year After Year</p>
        <p className="text-black leading-relaxed text-lg">
            An Annual Health Check is an essential step toward ensuring long-term wellness. It involves a comprehensive medical assessment designed to detect early signs of health issues, monitor chronic conditions, and assess overall health status. Key components include blood tests, imaging studies, and consultations with healthcare professionals to provide a detailed picture of your physical well-being. Regular annual check-ups empower individuals to take proactive steps to maintain good health, improve lifestyle choices, and prevent the onset of severe medical conditions. We cover all the bases. Partner with us to build a healthier, more productive workplace from day one.
        </p>
      </div>
      
      {/* Benefits Section */}
      <div>
        <h2 className="text-2xl font-semibold text-indigo-900 mb-4">Key Benefits</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
          {[
            { icon: <FaClipboardList />, text: "Early detection of health issues for timely intervention." },
            { icon: <FaUserNurse />, text: "Monitoring of vital health parameters such as blood pressure, cholesterol levels, and blood sugar." },
            { icon: <FaMedkit />, text: "Customized health and wellness plans tailored to individual needs." },
            { icon: <FaHandsHelping />, text: "Fostering Employee Well-being" },
            { icon: <FaAward />, text: "Enhancing Healthcare Excellence" }
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
              <div className="text-indigo-900 text-3xl mb-2">{item.icon}</div>
              <span className="text-gray-700">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AnnualHealthCheckPage;